/**
 * https://github.com/Tim-Erwin/oauth2-pkce
 */
import axios, { type AxiosInstance, HttpStatusCode } from 'axios'
import * as _ from 'lodash-es'

import {
  extractParamFromUrl,
  generateCodeChallengeAndVerifier,
  generateRandomState,
  parseWwwAuthenticateHeader,
} from './utils/common.util'
import { DefaultStorage } from './utils/storage.util'
import { sleep } from '@src/utils/common.util'

import { HEADER_WWW_AUTHENTICATE, RECOMMENDED_STATE_LENGTH } from './constants/common.constant'

import {
  type AccessContext,
  type Configuration,
  type Semaphore,
  type State,
  type TokenResponse,
} from './types/common.type'

/**
 * OAuth 2.0 client for authorization code flow with PKCE.
 */
export class Oauth {
  private state: State = {}
  private authCodeForAccessTokenPromise?: Promise<TokenResponse>
  private refreshTokenForAccessTokenPromise?: Promise<TokenResponse>
  private refreshToken?: string
  private semaphore: Record<string, Semaphore>

  constructor(
    readonly config: Configuration,
    private storage = DefaultStorage
  ) {
    /* authorizeSemaphore - служит для перехвата конкурентных запросов за токеном */
    const authorizeSemaphore = <Semaphore>{
      semaphore: Promise.resolve(),
      open: () => {},
      close: () => {},
    }

    authorizeSemaphore.close = () => {
      authorizeSemaphore.semaphore = new Promise(
        (resolve) =>
          (authorizeSemaphore.open = () => {
            resolve(true)
          })
      )
    }

    this.semaphore = {
      authorize: authorizeSemaphore,
    }

    // get state from storage
    this.recoverState().finally()
  }

  /**
   * Tells if the client is authorized or not.
   * This means the client has at least once successfully fetched an access token.
   * The access token could be expired.
   */
  get isAuthorized() {
    return !!this.state.accessToken
  }

  /**
   * Checks to see if the access token has expired.
   */
  get isAccessTokenExpired() {
    return !!this.state.accessTokenExpiry && new Date() >= new Date(this.state.accessTokenExpiry)
  }

  /**
   * Returns valid access token or starts authorization procedure.
   */
  async authorize() {
    if (this.isAuthorized) {
      if (this.isAccessTokenExpired && this.config.onAccessTokenExpiry) {
        return this.config.onAccessTokenExpiry.call(this)
      }

      return <string>this.state.accessToken
    }

    if (this.config.originUrlResolver) {
      this.state.originUrl = this.config.originUrlResolver()
    }

    // This will navigate to the auth server
    // where the user is asked to login and acknowledge the request to access the scopes.
    // So any code following this will never be executed.
    return this.requestAuthorizationCode()
  }

  /**
   * Resets the state of the client. Equivalent to "logging out" the user.
   */
  async reset() {
    this.state = {}
    await this.saveState()
    this.authCodeForAccessTokenPromise = undefined
    this.refreshTokenForAccessTokenPromise = undefined
  }

  /**
   * Fetch an authorization code via redirection.
   * In a sense, this function doesn't return because of the redirect behavior (uses `location.replace`).
   */
  async requestAuthorizationCode() {
    const { clientId, redirectUri, scopes } = this.config
    const { codeChallenge, codeVerifier } = await generateCodeChallengeAndVerifier()

    const stateQueryParam = generateRandomState(RECOMMENDED_STATE_LENGTH)

    this.state = { ...this.state, codeChallenge, codeVerifier, stateQueryParam }

    await this.saveState()

    let url =
      `${this.config.authorizationUrl}?response_type=code&` +
      `client_id=${encodeURIComponent(clientId)}&` +
      `redirect_uri=${encodeURIComponent(redirectUri)}&` +
      `state=${stateQueryParam}&` +
      `code_challenge=${encodeURIComponent(codeChallenge)}&` +
      'code_challenge_method=S256'

    if (scopes) {
      url += `&scope=${encodeURIComponent(scopes.join(' '))}`
    }

    location.replace(url)
  }

  /**
   * Read the code from the URL and store it.
   */
  async receiveCode() {
    const error = extractParamFromUrl('error', location.href)

    if (error) {
      throw new Error(error)
    }

    const stateQueryParam = extractParamFromUrl('state', location.href)

    if (stateQueryParam !== this.state.stateQueryParam) {
      throw new Error('Invalid returned state param.')
    }

    this.state.authorizationCode = extractParamFromUrl('code', location.href)

    if (!this.state.authorizationCode) {
      throw new Error('No auth code.')
    }

    await this.saveState()
  }

  /**
   * Using a previously fetched authorization code, try to get the auth tokens.
   * If there is no authorization code, return the previously fetched access token.
   */
  async getTokens() {
    await sleep(0)
    await this.semaphore.authorize?.semaphore

    if (!this.isAuthorized) {
      // закрытый семафор остановит конкурирующие запросы
      this.semaphore.authorize?.close()

      if (this.state.authorizationCode) {
        return this.exchangeAuthCodeForAccessToken()
      }

      if (!this.state.accessToken) {
        return this.config.onInvalidToken?.call(this)
      }
    }

    if (this.isAccessTokenExpired && this.config.onAccessTokenExpiry) {
      return this.config.onAccessTokenExpiry.call(this)
    }

    // семафор открыт после получения токена
    this.semaphore.authorize?.open()

    return Promise.resolve({
      accessToken: this.state.accessToken,
      idToken: this.state.idToken,
      refreshToken: this.state.refreshToken,
      scopes: this.state.scopes,
    })
  }

  async setImpersonateToken(token: string) {
    this.state.accessToken = token

    await this.saveState()
  }

  /**
   * Fetch an access token from the remote service.
   * This gets implicitly called by `getTokens()`.
   */
  async exchangeAuthCodeForAccessToken() {
    if (!this.authCodeForAccessTokenPromise) {
      this.authCodeForAccessTokenPromise = this.fetchAccessTokenUsingCode()
    }

    const tokenResponse = await this.authCodeForAccessTokenPromise

    this.authCodeForAccessTokenPromise = undefined
    this.state.authorizationCode = undefined

    return this.setTokens(tokenResponse)
  }

  /**
   * Refresh an access token from the remote service.
   */
  async exchangeRefreshTokenForAccessToken() {
    if (!this.refreshTokenForAccessTokenPromise) {
      this.refreshTokenForAccessTokenPromise = this.fetchAccessTokenUsingRefreshToken()
    }

    const tokenResponse = await this.refreshTokenForAccessTokenPromise

    this.refreshTokenForAccessTokenPromise = undefined

    return this.setTokens(tokenResponse)
  }

  useRequestInterceptor(api: AxiosInstance) {
    // We set an interceptor for each request to
    // include Bearer token to the request if user is logged in
    api.interceptors.request.use(async (config) => {
      const { accessToken } = await this.getTokens()

      config.headers.setAuthorization(`Bearer ${accessToken}`, true)

      return config
    })
  }

  useResponseInterceptor(api: AxiosInstance) {
    api.interceptors.response.use(
      async (response) => response,
      async (error: unknown) => {
        if (!axios.isAxiosError(error)) {
          return Promise.reject(error)
        }

        const { response } = error

        if (response?.status === HttpStatusCode.Unauthorized) {
          console.warn('AUTH INTERCEPT ERROR', response)

          const authenticateHeader = response.headers[HEADER_WWW_AUTHENTICATE]

          if (_.isString(authenticateHeader)) {
            const { error } = parseWwwAuthenticateHeader(authenticateHeader)

            if (error === 'invalid_grant' && this.config.onInvalidGrant) {
              await this.config.onInvalidGrant.call(this)
            }

            if (error === 'invalid_token' && this.config.onInvalidToken) {
              await this.config.onInvalidToken.call(this)
            }

            throw new Error(error)
          } else if (this.config.onInvalidToken) {
            await this.reset()

            await this.config.onInvalidToken.call(this)
          }
        }

        return Promise.reject(error)
      }
    )
  }

  useInterceptors(api: AxiosInstance) {
    this.useRequestInterceptor(api)
    this.useResponseInterceptor(api)
  }

  /**
   * Use the current grant code to fetch a fresh authorization token.
   */
  private async fetchAccessTokenUsingCode() {
    if (!this.state.codeVerifier) {
      console.warn('No code verifier is being sent.')
    } else if (!this.state.authorizationCode) {
      console.warn('No authorization grant code is being passed.')
    }

    const body =
      'grant_type=authorization_code&' +
      `code=${encodeURIComponent(this.state.authorizationCode || '')}&` +
      `redirect_uri=${encodeURIComponent(this.config.redirectUri)}&` +
      `client_id=${encodeURIComponent(this.config.clientId)}&` +
      `code_verifier=${this.state.codeVerifier}`

    return this.makeTokenRequest(this.config.tokenUrl, body)
  }

  /**
   * Fetch a new access token using the refresh token.
   */
  private fetchAccessTokenUsingRefreshToken() {
    if (!this.state.refreshToken) {
      console.warn('No refresh token is present.')
    }

    const body = `grant_type=refresh_token&refresh_token=${this.state.refreshToken}&client_id=${this.config.clientId}`

    return this.makeTokenRequest(this.config.tokenUrl, body)
  }

  private async makeTokenRequest(url: string, body: string) {
    const response = await fetch(url, {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      redirect: 'manual',
    })

    const data = response.body ? await response.json() : {}

    if (!response.ok) {
      await this.reset()

      if (response.type === 'opaqueredirect' && this.config.onAccessDenied) {
        await this.config.onAccessDenied.call(this)

        throw new Error('Access denied.')
      }

      if (data.error === 'invalid_grant' && this.config.onInvalidGrant) {
        await this.config.onInvalidGrant.call(this)

        throw new Error(data.error)
      }
    }

    return {
      accessToken: data.access_token,
      expiresIn: data.expires_in,
      idToken: data.id_token,
      refreshToken: data.refresh_token,
      scope: data.scope,
    } satisfies TokenResponse
  }

  private async setTokens(tokenResponse: TokenResponse) {
    if (tokenResponse.accessToken) {
      this.state.accessToken = tokenResponse.accessToken
      this.state.accessTokenExpiry = new Date(
        Date.now() + parseInt(tokenResponse.expiresIn ?? '0', 10) * 1000
      ).toString()
    }

    if (tokenResponse.idToken) {
      this.state.idToken = tokenResponse.idToken
    }

    if (tokenResponse.refreshToken) {
      this.state.refreshToken = tokenResponse.refreshToken
    }

    if (tokenResponse.scope) {
      // Multiple scopes are passed and delimited by spaces,
      // despite using the singular name "scope".
      this.state.scopes = tokenResponse.scope.split(' ')
    }

    await this.saveState()

    return {
      accessToken: this.state.accessToken,
      idToken: this.state.idToken,
      refreshToken: this.state.refreshToken,
      scopes: tokenResponse.scope ? this.state.scopes : [],
    } satisfies AccessContext
  }

  private async recoverState() {
    this.state = JSON.parse((await this.storage.loadState()) || '{}')

    if (!this.config.storeRefreshToken) {
      this.state.refreshToken = this.refreshToken
    }
  }

  private async saveState() {
    // save for future restore with empty refreshToken
    this.refreshToken = this.state.refreshToken

    const state = { ...this.state }

    if (!this.config.storeRefreshToken) {
      delete state.refreshToken
    }

    await this.storage.saveState(JSON.stringify(state))
  }

  async return() {
    location.replace(this.state.originUrl ?? '/')
  }
}
